<template>
  <div class="imgUpload">
    <el-upload
      v-if="multiple"
      :action="uploadUrl"
      :disabled="disabled"
      :on-success="handleUpload"
      :multiple="multiple"
      :limit="limit"
      :file-list="fileList"
      accept="image/png,image/jpg,image/jpeg,image/gif,image/bmp,image/webp"
      :list-type="listType"
      :before-upload="handleBefore"
      :on-remove="handleRemove"
      :on-exceed="handExceed"
      :headers="uploadHeader"
      :data="dataObj"
    >
      <i class="el-icon-plus" />
    </el-upload>
    <el-upload
      v-else
      :action="uploadUrl"
      :disabled="disabled"
      :on-success="handleUpload"
      :show-file-list="false"
      :accept="reqType.type==='IMAGE'?'image/png,image/jpg,image/jpeg,image/gif,image/bmp,image/webp':'application/json'"
      :list-type="listType"
      :before-upload="handleBefore"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :on-exceed="handExceed"
      :headers="uploadHeader"
      :data="dataObj"
    >
      <el-button :loading="loading" size="small" type="primary" v-if="listType=='text'">上传图片</el-button>
      <template v-else>
        <div v-loading="loading" class="loading"></div>
        <img v-if="fileList&&fileList.length>0&&(fileList[0].url||fileList[0].url_s3)&&reqType.type==='IMAGE'" :src="fileList[0].url||fileList[0].url_s3" class="avatar">
        <p v-else-if="fileList&&fileList.length>0&&(fileList[0].url||fileList[0].url_s3)&&reqType.type!=='IMAGE'" class="avatar">{{fileList[0].url}}</p>
        <div v-else>
          <i class="el-icon-plus avatar-uploader-icon"></i>
          <p class="textTip">{{textTip}}</p>
        </div>
      </template>
    </el-upload>
  </div>
</template>

<script>
import config from "@/config/env";
export default {
  props:{
    multiple:{
      type: Boolean,
      default: false
    },
    limit:{
      type: Number,
      default: 10
    },
    reqType: {
      type: Object,
      default:()=> {return {'type':'IMAGE'}}
    },
    imageFile: {
      type: Array,
      default:()=> []
    },
    listType: {
      type: String,
      default:'picture-card'
    },
    textTip:{
      type: String,
      default:'上传图片'
    },
    uploadApiUrl:{
      type: String,
      default:'/file/upload'
    },
    disabled:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      uploadUrl: "",
      uploadHeader: {
        'Authorization': sessionStorage.getItem('token')
      },
      fileList: [], // 图片
      fileListMul:[],
      loading:false,
      dataObj:{}
    }
  },
  watch:{
    imageFile(val){
      if(!this.multiple) {
        this.fileList=val
      } 
    },
    uploadApiUrl(val){
      this.uploadUrl=config.api+val
    }
  },
  created() {
    this.uploadUrl=config.api+this.uploadApiUrl
    this.fileList=this.imageFile
  },
  methods: {
    handleUpload(res,files, fileList) {
      this.loading=false
      if (res.code==200) {
        if(!this.multiple){
          this.fileList=[]
        }
        if(this.multiple){
          this.fileListMul=fileList.map(x=>{
            if(x.response&&x.response.data){
              return x.response.data
            } else if (x.fid) {
              return x
            }
          })
          this.fileListMul= this.fileListMul.filter(x=>{return x!=undefined})
          this.$emit("change",this.fileListMul)
        } else {
          this.fileList.push({...res.data})
          this.$emit("change",this.fileList)
        }
      } else {
        this.removeFailedFile(files, fileList);
        this.$message.error("上传失败");
      }
    },
    removeFailedFile(file, fileList) {
      const filteredList = fileList.filter(item => item.uid !== file.uid);
      this.fileList = filteredList;
      if (this.multiple) {
        this.fileListMul = filteredList;
      } 
      this.$emit("change", this.fileListMul || this.fileList);
    },
    // 预览图片
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleBefore(file) {
        this.loading = true;
        const is20M = file.size / 1024 / 1024 <= 20;
        if (!is20M) {
            this.$message.error("图片过大，文件大小不得大于20M");
            this.loading = false;
            return false;
        }
        if(file.type.indexOf('image')!==-1){
          return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = (e) => {
                  const img = new Image();
                  img.onload = () => {
                      const width = img.width;
                      const height = img.height;
                      this.dataObj = {...this.reqType, width, height,size:file.size};
                      resolve();
                  };
                  img.onerror = () => {
                      this.$message.error("无法读取图片，请确保文件是一个有效的图片格式");
                      this.loading = false;
                      reject();
                  };
                  img.src = e.target.result;
              };
              reader.onerror = () => {
                  this.$message.error("文件读取出错");
                  this.loading = false;
                  reject();
              };
              reader.readAsDataURL(file);
          });   
      }
    },
    handleRemove(file, fileList) {
      this.fileList = fileList
      this.$emit("change",this.fileList)
    },
    handExceed(files, fileList) {
      this.$message.error(`当前限制选择 ${this.limit} 个文件`);
    },
  }
};
</script>

<style lang="scss">
.imgUpload{
 display: inline-block;
 margin-right: 20px;
}
.imgUpload>div{
  flex-wrap: wrap;
}
.el-upload-list--picture-card {
  flex-wrap: wrap;
}
</style>
